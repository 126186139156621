<template>
  <div class="app">
    <meta
      name="viewport"
      content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
    />
    <!-- 路由出口 -->
      <router-view></router-view>
    <van-tabbar
    v-model="active" :route='true'
      active-color="#3296fa"
      inactive-color="#646566"
      class="tabbar"
      placeholder
      v-if="$route.meta.needTab"
      >
      <van-tabbar-item to="/homePage">
        <template #default>商城首页</template>
        <template #icon>
          <img v-show="active == 0" src="@/assets/tabbar/guahao01.png" alt="">
          <img v-show="active == 1" src="@/assets/tabbar/guahao00.png" alt="">
        </template>
      </van-tabbar-item>
      <van-tabbar-item to="/myInfo">
        <template  #default>我的</template>
        <template #icon>
          <img v-show="active == 1" src="@/assets/tabbar/wo01.png" alt="">
          <img v-show="active == 0" src="@/assets/tabbar/wo00.png" alt="">
        </template>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
export default {
  name: 'home',
  data () {
    return {
      active: 0
    }
  },
  // watch () {
  //   // active:{
  //   //   handler(newNum,oldNum) {
  //   //     if(newNum == 1) {
  //   //       // this.$router.push({path:'/home'})
  //   //     }
  //   //   }
  //   // }
  // },
  created () {},
  methods : {
    onSwipeLeft () {
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="less">
.app {
  height: 100%;
  .fz14 {
    font-size: 14px;
  }
  .flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
body,
#html {
  height: 100%;
}

</style>
